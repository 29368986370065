import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroHome from "../components/template-partials/homepage/hero-home"
import SkivoloHome from "../components/template-partials/homepage/skivolo-home"
import Section from "../components/template-partials/global-components/section"
import ShareButtons from "../components/template-partials/global-components/share-buttons"

import MissionImage from "../components/template-partials/homepage/missionImage";
import SkivoloBannerMobile from "../components/template-partials/homepage/skivolobannerhome";
import FancyImage from "../components/template-partials/global-components/fancy-image"

import BravinHome from "../components/template-partials/homepage/bravin-home"
import BravinHomeMobile from "../components/template-partials/homepage/bravin-home-mobile";

import cardResources from "../images/hp-card-resources.jpg"
import cardAvo from "../images/hp-card-avo.jpg"
import cardCaring from "../images/hp-card-caring.jpg"
import cardQad from "../images/hp-card-qad.jpg"
import landd from "../images/landd.png";
import zaraRocket from "../images/Zara-rocket@2x.png";

import devon from "../images/devon-leah.png"
import banner from '../images/Braving_Neuroblastoma_Web_Banner.svg'

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../components/variables.module.scss';


const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Neuroblastoma-Info | Support for Your Journey" description="A neuroblastoma diagnosis can be overwhelming. Find helpful information about your child’s diagnosis and treatment journey, as well as resources and support." />
      <Section className="hp-violator" 
      style={{
        padding: '0',
        }}>   
          <a href='https://www.facebook.com/BravingNeuroblastoma' target="_blank" style={{
                display: 'flex',
                justifyContent: 'center',
                minWidth: '100px',
                maxHeight: '250px',
                width: '100%',
                background: 'linear-gradient(to right, rgba(31,120,124,1) 0%, rgba(31,120,124,1) 50%, rgba(193,236,237,1) 50%, rgba(193,236,237,1) 100%)'
          }}>
          <img src={banner} alt='facebook call to action' style={{objectFit: 'contain'}} />
          </a>
        {/* <div className="row row--inner2 align-center">

        <div className="columns small-8 large-9">
            <div className="row hp-violator__head">
              <div className="columns">
                <h3 className="h3 tc-viridian jost-med">Still Standing Together</h3>
              </div>
            </div>
            <div className="row hp-violator__copy show-for-large">
              <div className="columns">
                <p>Find hope through one family’s story of resilience in the face of neuroblastoma. </p>
                <p><Link to="https://vimeo.com/871112390/bd44571455?share=copy">Watch the video &rsaquo;</Link></p>
              </div>
            </div>
          </div>
          <div className="columns small-4 shrink hide-for-large hp-violator__image-bottoma">
            <FancyImage
                url={devon}
                alt="Devon and Leah Still"
                width="110"
              />
          </div>
          <div className="columns small-12 hide-for-large">
            <p>Whether you're a parent, caregiver, or child, coping with childhood cancer can be overwhelming. Watch as a panel of patients, parents, and an expert mental health clinician have an open conversation about their individual journeys with childhood cancer, the psychological effects, as well as tips and coping mechanisms they learned along the way.</p>
            <p><Link to="/support/mental-health/">Watch the panel discussion &rsaquo;</Link></p>
          </div>
          <div className="columns shrink show-for-large hp-violator__image-bottoma">
            <FancyImage
                url={devon}
                alt="Devon and Leah Still"
                width="200"
              />
          </div>
        </div> */}
      </Section>
      {/* <></> is a react fragment */}
      <>
        <HeroHome/>
      </>
      {/* style={{backgroundColor: SassVars.viridian}} */}
      <Section className="homepage__resources">
        <div className="row row--inner">
          <div className="columns text-center">
            <h2 className="section__heading uppercase h1">
              Resources <em className="lowercase">and</em> Support
            </h2>
            <p className="section__p">When your child is diagnosed with neuroblastoma, it can feel overwhelming. Here you’ll find resources to help support you, your family, and your loved ones.</p>
          </div>
        </div>
      </Section>
      <Section className="homepage__resources-grid" style={{backgroundColor: SassVars.slateBlue}}>
        <div className="row row--inner">
          <div className="columns small-12 large-expand">
            <Link to="/support/caregiver-support/" className="card card--shadow">
              <div className="row align-middle">
                <div className="columns shrink show-for-large">
                  <img className="card__img--rounded" src={cardCaring} alt="Caring for the caregiver" width="90" height="auto"/>
                </div>
                <div className="columns">
                  <p><strong>Caring for the caregiver</strong></p>
                  <p>Find ways to take care of yourself throughout your child’s treatment. </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="columns">
            <Link to="/resources/downloadable-resources/" className="card card--shadow">
              <div className="row align-middle">
                <div className="columns shrink show-for-large">
                  <img className="card__img--rounded" src={cardResources} alt="Helpful Resources" width="90" height="auto"/>
                </div>
                <div className="columns">
                  <p><strong>Downloadable resources <span><br className="show-for-large"/>&amp; videos</span></strong></p>
                  <p>Explore our library of helpful resources.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns columns small-12 large-expand">
            <Link to="/resources/doctor-discussion-guide/" className="card card--shadow">
              <div className="row align-middle">
                <div className="columns shrink show-for-large">
                  <img className="card__img--rounded" src={cardQad} alt="Questions to ask your doctor" width="90" height="auto"/>
                </div>
                <div className="columns">
                  <p><strong>Questions to ask your doctor</strong></p>
                  <p>Build a set of questions to ask before, during, and after treatment.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="columns">
            <Link to="/resources/support-groups/" className="card card--shadow">
              <div className="row align-middle">
                <div className="columns shrink show-for-large">
                  <img className="card__img--rounded" src={cardAvo} alt="Neuroblastoma support groups & advocacy organizations" width="90" height="auto"/>
                </div>
                <div className="columns">
                  <p><strong>Neuroblastoma support groups and advocacy organizations</strong></p>
                  <p>Connect with other neuroblastoma families through support groups and advocacy organizations.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Section>
      <Section className="homepage__skivolo">
        <BravinHomeMobile/>
        <BravinHome>
          <div className="row row--inner align-center">
            <div className="columns small-12 large-9 text-center">
              <div className="card card--destroy-small">
                <h3 className="section__heading uppercase h1">
                  BRAVING <br className="hide-for-large"/><em className="lowercase">Neuroblastoma</em>
                </h3>
                <p>As part of the Braving Neuroblastoma initiative supporting families touched by neuroblastoma, former NFL player Devon Still and his daughter Leah, a pediatric high-risk neuroblastoma survivor, share their experiences.</p>
                <div className="row btn-group align-center">
                  <div className="columns small-12 medium-shrink">
                    <Link to="/support/braving-neuroblastoma/" className="btn btn--primary">See their journey here</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BravinHome>
      </Section>
      <Section className="homepage__skivolo">
        <SkivoloBannerMobile/>
        <SkivoloHome src="skivolokidzone_bannerimage.jpg">
          <div className="row row--inner align-center">
            <div className="columns small-12 large-9 text-center">
              <div className="card card--destroy-small">
                <h3 className="section__heading uppercase h1">
                  Skivolo's <br className="hide-for-large"/><em className="lowercase">Kid Zone</em>
                </h3>
                <p>If your child or their sibling is curious about cancer and their treatment, Skivolo is here to help!</p>
                <div className="row btn-group align-center">
                  <div className="columns small-12 medium-shrink">
                    <Link to="/resources/skivolos-kid-zone/" className="btn btn--primary">Visit Skivolo’s Kid Zone</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SkivoloHome>
      </Section>
      <Section className="homepage__mission">
        <div className="row row--inner small-col-reverse align-middle align-justify">
          <div className="columns large-7 small-12">
            <h2 className="section__subheading h5">Committed to supporting you</h2>
            <h3 className="section__heading uppercase h1">Our Mission</h3>
            <p className="section__p">United Therapeutics Oncology is dedicated to the care of children with neuroblastoma. We are honored to serve this brave community and are committed to providing comprehensive care to the families and caregivers touched by this childhood cancer.</p>
          </div>
          <div className="columns small-12 large-4">
            <MissionImage/>
          </div>
        </div>
      </Section>
      <Section className="section--no-pad">
        <div className="row row--inner">
          <div className="columns small-12">
            <ShareButtons/>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default IndexPage
